import * as React from "react"
import styled from "styled-components"
import Header from "../header"
import Footer from "../footer"
import Head from "./head"
import "../../styles.css"

const MainWrapper = styled.div`
  padding-top: 88px;
`

const Layout = (props) => {
  return (
    <>
      <Head {...props} />
      <Header />
      <MainWrapper id="layout--main-wrapper">{props.children}</MainWrapper>
      <Footer />
    </>
  )
}

export default Layout
