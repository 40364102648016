import { useEffect, useState, useCallback } from "react"
import { gsap } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { useWindowScroll, useMedia, useEvent } from "react-use"

const triggerClassName = "#layout--main-wrapper"
const elementClassNames = "#header-brand-logo, #header-cta"

const enterTl = gsap.timeline({ delay: 1 })

const useHeader = () => {
  const isMobile = useMedia("(max-width: 480px)")
  const { y } = useWindowScroll()
  const topOfWindow = y < 3

  const [isOpen, setOpen] = useState(false)
  const [trackScroll, setTrackScroll] = useState(false)

  const handleEnter = () => {
    enterTl.from(elementClassNames, {
      opacity: 0,
      duration: 1,
      ease: "power1.out",
    })
  }

  const onScroll = useCallback(() => {
    setTrackScroll(true)
    enterTl.progress(1)
  }, [])

  useEvent("scroll", onScroll)

  // Initializes to reveal once font families are loaded
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.to("#header", { visibility: "visible" })
  }, [])

  // Handles initial rendering
  useEffect(() => {
    handleEnter()
  }, [])

  // Handles the scroll-driven animations
  // useEffect(() => {
  //   if (!trackScroll) return

  //   const exitConfig = {
  //     stagger: 0.25,
  //     y: -400,
  //     scrollTrigger: {
  //       trigger: triggerClassName,
  //       scrub: 1,
  //       start: 0,
  //     },
  //   }

  //   gsap.to(elementClassNames, exitConfig)
  // }, [trackScroll])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "visible"
    }
    setOpen(false)
  }, [isOpen])

  return {
    isOpen,
    setOpen,
    customStyling: isMobile && !topOfWindow,
  }
}

export default useHeader
