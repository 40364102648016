import { useEffect } from "react"
import { gsap } from "gsap"

const useFooter = () => {
  useEffect(() => {
    const tl = gsap.timeline({ delay: 1 })
    tl.to(".footer--social-link--li, .footer--copyright", {
      visibility: "visible",
    })
    tl.from(".footer--social-link--li", {
      opacity: 0,
      stagger: 0.35,
      duration: 1,
    })
    tl.from(".footer--copyright", { opacity: 0 })
  }, [])
}

export default useFooter
