import * as React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import {
  Container,
  Flex,
  // FlexList,
  Space,
  NavLink,
  Button,
  VisuallyHidden,
} from "../ui"
import {
  desktopHeaderNavWrapper,
  mobileHeaderNavWrapper,
  mobileNavSVGColorWrapper,
} from "./header.css"
// import NavItemGroup from "./nav-item-group"
import BrandLogo from "../shared/brand-logo"
import useHeader from "./useHeader"

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  visibility: hidden;
  z-index: 1000;
  background-color: ${({ topStyle }) => (topStyle ? "white" : "transparent")};
  box-shadow: ${({ topStyle }) =>
    topStyle ? "0 2px 2px -2px rgba(0,0,0,.2)" : "none"};
`

const query = graphql`
  query {
    layout {
      header {
        id
        navItems {
          id
          navItemType
          ... on NavItem {
            href
            text
          }
          ... on NavItemGroup {
            name
            navItems {
              id
              href
              text
              description
              icon {
                alt
                gatsbyImageData
              }
            }
          }
        }
        cta {
          id
          href
          text
        }
      }
    }
  }
`

export default function Header() {
  const data = useStaticQuery(query)
  const { cta } = data.layout.header
  // const { navItems, cta } = data.layout.header

  const { isOpen, customStyling } = useHeader()

  return (
    <StyledHeader id="header" topStyle={customStyling}>
      <Container className={desktopHeaderNavWrapper}>
        <Space size={2} />
        <Flex variant="spaceBetween" id="header-brand-logo">
          {/* <NavLink to="/"> */}
          {/* <VisuallyHidden>Home</VisuallyHidden> */}
          <BrandLogo />
          {/* </NavLink> */}
          {/* <nav>
            <FlexList gap={4}>
              {navItems &&
                navItems.map((navItem) => (
                  <li key={navItem.id}>
                    {navItem.navItemType === "Group" ? (
                      <NavItemGroup
                        name={navItem.name}
                        navItems={navItem.navItems}
                      />
                    ) : (
                      <NavLink to={navItem.href}>{navItem.text}</NavLink>
                    )}
                  </li>
                ))}
            </FlexList>
          </nav> */}
          <div id="header-cta">
            {cta && <Button to={cta.href}>{cta.text}</Button>}
          </div>
        </Flex>
      </Container>
      <Container className={mobileHeaderNavWrapper[isOpen ? "open" : "closed"]}>
        <Space size={2} />
        <Flex variant="spaceBetween">
          <span
            className={
              mobileNavSVGColorWrapper[isOpen ? "reversed" : "primary"]
            }
          >
            <NavLink to="/">
              <VisuallyHidden>Home</VisuallyHidden>
              <BrandLogo />
            </NavLink>
          </span>
          <Flex>
            <Space />
            {cta && (
              <Button to={cta.href} variant={isOpen ? "reversed" : "primary"}>
                {cta.text}
              </Button>
            )}
            {/* <Nudge right={3}>
              <InteractiveIcon
                title="Toggle menu"
                onClick={() => setOpen(!isOpen)}
                className={
                  mobileNavSVGColorWrapper[isOpen ? "reversed" : "primary"]
                }
              >
                {isOpen ? <X /> : <Menu />}
              </InteractiveIcon>
            </Nudge> */}
          </Flex>
        </Flex>
      </Container>
      {/* {isOpen && (
        <div className={mobileNavOverlay}>
          <nav>
            <FlexList responsive variant="stretch">
              {navItems?.map((navItem) => (
                <li key={navItem.id}>
                  {navItem.navItemType === "Group" ? (
                    <NavItemGroup
                      name={navItem.name}
                      navItems={navItem.navItems}
                    />
                  ) : (
                    <NavLink to={navItem.href} className={mobileNavLink}>
                      {navItem.text}
                    </NavLink>
                  )}
                </li>
              ))}
            </FlexList>
          </nav>
        </div>
      )} */}
    </StyledHeader>
  )
}
