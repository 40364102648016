import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Instagram, Linkedin, GitHub } from "react-feather"
import {
  Container,
  Flex,
  FlexList,
  Box,
  Space,
  NavLink,
  Text,
  IconLink,
  VisuallyHidden,
} from "../ui"
// import BrandLogo from "../shared/brand-logo"
import useFooter from "./useFooter"

const SOCIAL_LINKS = {
  INSTAGRAM: {
    url: "https://instagram.com",
    name: "Instagram",
    icon: <Instagram />,
  },
  LINKEDIN: {
    url: "https://linkedin.com",
    name: "LinkedIn",
    icon: <Linkedin />,
  },
  GITHUB: {
    url: "https://github.com",
    name: "GitHub",
    icon: <GitHub />,
  },
}

const getSocialURL = ({ service, username }) => {
  const domain = SOCIAL_LINKS[service]?.url
  if (!domain) return false
  return `${domain}/${username}`
}

const getSocialIcon = ({ service }) => {
  return SOCIAL_LINKS[service]?.icon
}

const getSocialName = ({ service }) => SOCIAL_LINKS[service]?.name

const query = graphql`
  query {
    layout {
      footer {
        id
        links {
          id
          href
          text
        }
        meta {
          id
          href
          text
        }
        copyright
        socialLinks {
          id
          service
          username
        }
      }
    }
  }
`

export default function Footer() {
  const data = useStaticQuery(query)
  const { /* links, */ meta, socialLinks, copyright } = data.layout.footer

  useFooter()

  return (
    <Box
      as="footer"
      paddingY={4}
      style={{ position: "fixed", bottom: 0, left: 0 }}
    >
      <Container>
        <Flex variant="start" responsive>
          {/* <NavLink to="/">
            <VisuallyHidden>Home</VisuallyHidden>
            <BrandLogo />
          </NavLink> */}
          <Space />
          <FlexList>
            {socialLinks &&
              socialLinks.map((link) => {
                const url = getSocialURL(link)
                return (
                  url && (
                    <li
                      key={link.id}
                      className="footer--social-link--li"
                      style={{ visibility: "hidden" }}
                    >
                      <IconLink to={url} target="_blank">
                        <VisuallyHidden>{getSocialName(link)}</VisuallyHidden>
                        {getSocialIcon(link)}
                      </IconLink>
                    </li>
                  )
                )
              })}
          </FlexList>
        </Flex>
        <Space size={5} />
        <Flex variant="start" responsive>
          {/* <FlexList variant="start" responsive>
            {links &&
              links.map((link) => (
                <li key={link.id}>
                  <NavLink to={link.href}>{link.text}</NavLink>
                </li>
              ))}
          </FlexList> */}
          <Space />
          {/* <FlexList>
            {meta &&
              meta.map((link) => (
                <li key={link.id}>
                  <NavLink to={link.href}>
                    <Text variant="small">{link.text}</Text>
                  </NavLink>
                </li>
              ))}
          </FlexList> */}
          <Text variant="small" className="footer--copyright" style={{ visibility: "hidden" }}>{copyright}</Text>
        </Flex>
      </Container>
      <Space size={3} />
    </Box>
  )
}
