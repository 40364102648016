import * as React from "react"
import styled from "styled-components"

const LogoWrapper = styled.div`
  width: 100px;
`

export default function GatsbyContentfulLogo() {
  return (
    <LogoWrapper>
      <svg
        id="svg"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0, 0, 400,400"
      >
        <path
          id="path0"
          d="M59.531 16.563 C 58.119 17.975,58.197 65.885,59.613 67.061 C 60.324 67.651,82.532 67.988,132.855 68.173 C 204.471 68.436,205.143 68.453,212.812 70.196 C 339.965 99.106,361.701 264.785,245.992 323.096 C 223.888 334.236,207.474 336.688,154.883 336.706 L 120.313 336.719 120.313 278.906 L 120.313 221.094 146.094 221.094 C 166.200 221.094,171.874 220.879,171.870 220.117 C 171.866 219.314,163.015 207.728,151.726 193.750 C 145.036 185.467,92.333 118.264,91.016 116.338 C 88.820 113.128,88.602 113.286,79.644 124.609 C 75.224 130.195,69.794 137.051,67.576 139.844 C 65.359 142.637,62.520 146.328,61.269 148.047 C 60.017 149.766,58.789 151.348,58.540 151.563 C 58.291 151.777,54.799 156.172,50.780 161.328 C 43.769 170.321,37.191 178.671,27.000 191.511 C 24.396 194.791,19.980 200.387,17.188 203.947 C 14.395 207.507,10.439 212.527,8.398 215.103 C 6.357 217.679,4.688 220.081,4.688 220.441 C 4.688 220.800,16.815 221.094,31.637 221.094 L 58.587 221.094 58.786 304.492 L 58.984 387.891 129.297 388.105 C 215.873 388.368,219.806 388.041,248.828 378.153 C 311.672 356.744,362.773 295.937,371.462 232.227 C 371.770 229.971,372.380 228.125,372.818 228.125 C 374.321 228.125,373.019 180.645,371.243 170.703 C 356.168 86.309,287.075 23.349,201.949 16.436 C 187.081 15.229,60.753 15.341,59.531 16.563 "
          stroke="none"
          fill="currentColor"
        ></path>
      </svg>
    </LogoWrapper>
  )
}
